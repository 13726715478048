import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../../utilities/UserContext';
import ReportPDF from '../Reports/ReportPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import styles from './DownloadReport.module.css';

const DownloadReport = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const { isPDFLoading } = userContext;
    const [forceLoading, setForceLoading] = useState(true);
    const [isGenerating, setIsGenerating] = useState(false);
    const [reportParams, setReportParams] = useState(null);

    // Timer for initial loading state
    useEffect(() => {
        const timer = setTimeout(() => {
            setForceLoading(false);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!userContext.signed.signed) {
            navigate('/');
            return;
        }

        if (!location.state || !location.state.type === 'pdf-download') {
            navigate('/');
            return;
        }

        setReportParams({
            clientid: location.state.clientid,
            assessmenttypeid: location.state.assessmenttypeid,
            assessmentid: location.state.assessmentid,
            userid: userContext.userid.userid,
            username: userContext.username.username,
            token: userContext.token.token
        });
    }, [userContext, location, navigate]);

    useEffect(() => {
        setIsGenerating(isPDFLoading);
    }, [isPDFLoading]);

    if (!reportParams) {
        return (
            <div className={styles.loadingContainer}>
                <div>Error: Report parameters not available</div>
            </div>
        );
    }

    const getButtonContent = (pdfStatus) => {
        const { loading: pdfLibLoading, error } = pdfStatus;

        if (error) {
            return (
                <div className={styles.errorState}>
                    <span>Error preparing report.</span>
                    <span>Please try again</span>
                </div>
            );
        }

        if (forceLoading || isGenerating || pdfLibLoading) {
            return (
                <div className={styles.loadingState}>
                    <div className={styles.loadingSpinnerSmall}></div>
                    <span>
                        {forceLoading 
                            ? "Initializing report generator..." 
                            : isGenerating 
                                ? "Generating report components..." 
                                : "Preparing PDF document..."}
                    </span>
                </div>
            );
        }

        return (
            <div className={styles.readyState}>
                <span>Download Report</span>
            </div>
        );
    };

    return (
        <div className={styles.downloadContainer}>
            <h2>Welcome {userContext.user.user}</h2>
            <p>Your report is being prepared for download</p>
            
            <div className={styles.buttonContainer}>
                <PDFDownloadLink
                    document={<ReportPDF {...reportParams} />}
                    fileName="assessment_report.pdf"
                    className={`${styles.downloadButton} ${(forceLoading || isGenerating) ? styles.loading : ''}`}
                >
                    {(status) => getButtonContent(status)}
                </PDFDownloadLink>
            </div>
        </div>
    );
};

export default DownloadReport;