import React, { useState, useEffect, useContext, useCallback } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import styles from "./TreeView.module.css";
import AssessmentsIcon from "./img/Assessments.png";
import AssessmentTypeIcon from "./img/AssessmentType.png";
import ClientIcon from "./img/Single-Organization.png";
import AssessmentIcon from "./img/Assessment.png";
import InfoIcon from "./img/info.png";
import RefreshIcon from "./img/refresh.png";

const LoadingSpinner = () => (
  <div className={styles.loadingContainer}>
    <div className={styles.spinner}></div>
    <p>Cargando evaluaciones...</p>
  </div>
);

const transformAssessmentData = (data) => {
  // Agrupamos por tipo de evaluación
  const assessmentTypeMap = data.reduce((acc, item) => {
    if (!acc[item.assessmentypeid]) {
      acc[item.assessmentypeid] = {
        id: item.assessmentypeid,
        type: "assessmentType",
        label: item.assessmentype,
        children: {},
      };
    }

    // Agrupamos por cliente dentro de cada tipo de evaluación
    if (!acc[item.assessmentypeid].children[item.clientid]) {
      acc[item.assessmentypeid].children[item.clientid] = {
        id: item.clientid,
        type: "client",
        label: item.client,
        children: [],
      };
    }

    // Agregamos la evaluación específica
    acc[item.assessmentypeid].children[item.clientid].children.push({
      id: item.assessmentid,
      type: "assessment",
      label: item.assessment,
    });

    return acc;
  }, {});

  // Convertimos el mapa en estructura de árbol
  return Object.values(assessmentTypeMap).map((assessmentType) => ({
    ...assessmentType,
    children: Object.values(assessmentType.children),
  }));
};

const TreeItem = ({
  type,
  label,
  id,
  children,
  level = 0,
  onNodeSelect,
  onRefresh,
  isRoot = false,
  expandedNodes = [], // Valor por defecto para nodos expandidos
  onToggleExpand = () => {} // Función por defecto para manejo de expansión
}) => {
  // Generamos un ID único para el nodo basado en su tipo y otros identificadores
  const nodeId = isRoot ? `root-${type}` : `${type}-${id}-${label}`;
  const isExpanded = expandedNodes.includes(nodeId);

  const [showInfoIcon, setShowInfoIcon] = useState(false);
  const [showRefreshIcon, setShowRefreshIcon] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    if (children && children.length > 0) {
      onToggleExpand(nodeId);
    }
  };

  const handleRefreshClick = (e) => {
    e.stopPropagation();
    if (onRefresh) {
      onRefresh(type);
    }
  };

  const handleInfoClick = (e) => {
    e.stopPropagation();
    if (type === "assessment") {
      onNodeSelect(id, type);
    }
  };

  const handleMouseEnter = () => {
    if (type === "assessment") {
      setShowInfoIcon(true);
    }
    if (isRoot) {
      setShowRefreshIcon(true);
    }
  };

  const handleMouseLeave = () => {
    setShowInfoIcon(false);
    setShowRefreshIcon(false);
  };

  // Función para seleccionar el icono correcto según el tipo de nodo
  const getIcon = () => {
    switch (type) {
      case "root":
        return AssessmentsIcon;
      case "assessmentType":
        return AssessmentTypeIcon;
      case "client":
        return ClientIcon;
      case "assessment":
        return AssessmentIcon;
      default:
        return AssessmentsIcon;
    }
  };

  return (
    <div className={styles.treeItem} style={{ marginLeft: `${level * 20}px` }}>
      <div
        className={styles.treeItemContent}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className={styles.toggle}>
          {children && children.length > 0 ? (isExpanded ? "▼" : "▶") : "-"}
        </span>
        <img src={getIcon()} alt={type} className={styles.icon} />
        <span className={styles.label}>{label}</span>
        <div className={styles.iconContainer}>
          {showRefreshIcon && (
            <img
              src={RefreshIcon}
              alt="Refresh"
              className={styles.refreshIcon}
              onClick={handleRefreshClick}
            />
          )}
          {showInfoIcon && (
            <img
              src={InfoIcon}
              alt="Info"
              className={styles.infoIcon}
              onClick={handleInfoClick}
            />
          )}
        </div>
      </div>
      {isExpanded && children && children.length > 0 && (
        <div className={styles.childrenContainer}>
          {children.map((child, index) => (
            <TreeItem
              key={index}
              {...child}
              onNodeSelect={onNodeSelect}
              onRefresh={onRefresh}
              level={level + 1}
              expandedNodes={expandedNodes}
              onToggleExpand={onToggleExpand}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const TreeAssessmentView = ({ onNodeSelect }) => {
  const context = useContext(UserContext);
  const [assessments, setAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // Estado para mantener el registro de nodos expandidos
  const [expandedNodes, setExpandedNodes] = useState([]);

  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;

  // Función memoizada para manejar la expansión/contracción de nodos
  const handleToggleExpand = useCallback((nodeId) => {
    setExpandedNodes(prev => {
      const currentExpanded = Array.isArray(prev) ? prev : [];
      if (currentExpanded.includes(nodeId)) {
        return currentExpanded.filter(id => id !== nodeId);
      } else {
        return [...currentExpanded, nodeId];
      }
    });
  }, []);

  // Función memoizada para obtener las evaluaciones
  const fetchAssessments = useCallback(async () => {
    setIsLoading(true);
    try {
      const assessmentData = {
        transactiontype: "getAssessmentTree",
        userid,
        username,
        token,
      };
      const response = await fetchUtility(assessmentData, "assessment");
      setAssessments(response);
    } catch (error) {
      console.error("Error al obtener evaluaciones:", error);
    } finally {
      setIsLoading(false);
    }
  }, [userid, username, token]);

  // Efecto para cargar los datos iniciales
  useEffect(() => {
    fetchAssessments();
  }, [fetchAssessments]);

  // Función para manejar la actualización del árbol
  const handleRefresh = async () => {
    await fetchAssessments();
  };

  // Preparación de los datos para el árbol
  const treeData = {
    label: "Assessments",
    type: "root",
    isRoot: true,
    children: transformAssessmentData(assessments),
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.treeView}>
      <TreeItem
        {...treeData}
        onNodeSelect={onNodeSelect}
        onRefresh={handleRefresh}
        expandedNodes={expandedNodes}
        onToggleExpand={handleToggleExpand}
      />
    </div>
  );
};

export default TreeAssessmentView;
