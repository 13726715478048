import React, { useState, useEffect, useContext, useCallback } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import styles from "./TreeView.module.css";
import UsersIcon from "./img/Users.svg";
import SingleUserIcon from "./img/Single-User.svg";
import OrganizationsIcon from "./img/Organizations.png";
import InfoIcon from "./img/info.png";
import RefreshIcon from "./img/refresh.png";
import SingleOrganizationIcon from "./img/Single-Organization.png";
import AddIcon from "./img/add.png";

const LoadingSpinner = () => (
  <div className={styles.loadingContainer}>
    <div className={styles.spinner}></div>
    <p>Cargando datos...</p>
  </div>
);

const TreeItem = ({
  type,
  label,
  id,
  children,
  level,
  treeLevel = 0,
  parentId,
  onNodeSelect,
  onAddOrganization,
  onRefresh,
  isRoot = false,
  expandedNodes = [], // Valor por defecto
  onToggleExpand = () => {}, // Función vacía por defecto
}) => {
  // Aseguramos que expandedNodes sea un array incluso si es undefined
  const actualExpandedNodes = Array.isArray(expandedNodes) ? expandedNodes : [];
  const nodeId = `${type}-${label}`;
  const isExpanded = actualExpandedNodes.includes(nodeId);

  const handleClick = (e) => {
    e.stopPropagation();
    if (children && children.length > 0) {
      onToggleExpand(nodeId);
    }
  };

  const [showAddIcon, setShowAddIcon] = useState(false);
  const [showInfoIcon, setShowInfoIcon] = useState(false);
  const [showRefreshIcon, setShowRefreshIcon] = useState(false);

  const handleInfoClick = (e) => {
    e.stopPropagation();
    onNodeSelect(id, type);
  };

  const handleAddClick = (e) => {
    e.stopPropagation();
    if (type === "organizations") {
      onAddOrganization({
        id,
        label,
        level,
        parentId,
      });
    }
  };

  const handleMouseEnter = () => {
    if (type === "organizations" && !isRoot) {
      setShowAddIcon(true);
    }
    if (type === "users" && !isRoot && !children) {
      setShowInfoIcon(true);
    }
    if (isRoot) {
      setShowRefreshIcon(true);
    }
  };

  const handleMouseLeave = () => {
    setShowAddIcon(false);
    setShowInfoIcon(false);
    setShowRefreshIcon(false);
  };

  const handleRefreshClick = (e) => {
    e.stopPropagation();
    if (onRefresh) {
      onRefresh(type);
    }
  };

  const getIcon = () => {
    if (type === "users") {
      return isRoot ? UsersIcon : SingleUserIcon;
    } else if (type === "organizations") {
      return isRoot ? OrganizationsIcon : SingleOrganizationIcon;
    }
  };

  const indentationLevel = isRoot ? 0 : treeLevel;
  const indentationAmount = 24;

  return (
    <div
      className={styles.treeItem}
      style={{
        paddingLeft: `${indentationLevel * indentationAmount}px`,
      }}
    >
      <div
        className={styles.treeItemContent}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className={styles.toggle}>
          {children && children.length > 0 ? (isExpanded ? "▼" : "▶") : "-"}
        </span>
        <img src={getIcon()} alt={type} className={styles.icon} />
        <span className={styles.label}>{label}</span>
        <div className={styles.iconContainer}>
          {showRefreshIcon && (
            <img
              src={RefreshIcon}
              alt="Refresh"
              className={styles.refreshIcon}
              onClick={handleRefreshClick}
            />
          )}
          {showAddIcon && (
            <img
              src={AddIcon}
              alt="Add"
              className={styles.addIcon}
              onClick={handleAddClick}
            />
          )}
          {showInfoIcon && (
            <img
              src={InfoIcon}
              alt="Info"
              className={styles.infoIcon}
              onClick={handleInfoClick}
            />
          )}
        </div>
      </div>
      {isExpanded && children && children.length > 0 && (
        <div className={styles.childrenContainer}>
          {children.map((child, index) => (
            <TreeItem
              key={index}
              {...child}
              onNodeSelect={onNodeSelect}
              onAddOrganization={onAddOrganization}
              onRefresh={onRefresh}
              treeLevel={treeLevel + 1}
              type={type}
              expandedNodes={actualExpandedNodes}
              onToggleExpand={onToggleExpand}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const groupUsersByClient = (users) => {
  // Creamos un objeto para agrupar los usuarios por cliente
  const groupedUsers = users.reduce((acc, user) => {
    if (!acc[user.client]) {
      acc[user.client] = [];
    }
    acc[user.client].push(user);
    return acc;
  }, {});

  // Convertimos el objeto agrupado en el formato que necesita el árbol
  return Object.entries(groupedUsers).map(([client, users]) => ({
    label: client,
    type: "users",
    treeLevel: 1,
    // Transformamos cada usuario en un nodo hijo
    children: users.map((user) => ({
      label: user.username,
      id: user.userid,
      type: "users",
      treeLevel: 2,
    })),
  }));
};

// Esta función transforma los datos de una organización en formato de árbol
const transformOrganizationData = (org, currentLevel = 0) => {
  return {
    label: org.client,
    id: org.clientid,
    type: "organizations",
    level: org.level,
    parentId: org.client2parentclient,
    // Recursivamente transformamos los hijos si existen
    children: org.children
      ? org.children.map((child) =>
          transformOrganizationData(child, currentLevel + 1)
        )
      : [],
    treeLevel: currentLevel,
  };
};

const TreeUserView = ({ onNodeSelect, onAddOrganization }) => {
  // Contexto y estados necesarios para el componente
  const context = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // Estado para mantener el registro de nodos expandidos
  const [expandedNodes, setExpandedNodes] = useState([]);

  // Extraemos las propiedades necesarias del contexto
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;

  // Función para obtener los usuarios del sistema
  const fetchUsers = useCallback(async () => {
    try {
      const usersData = {
        transactiontype: "getUsers",
        userid,
        username,
        token,
      };
      const responseUsers = await fetchUtility(usersData, "manageuser");
      setUsers(responseUsers);
    } catch (error) {
      console.error("Error al obtener usuarios:", error);
    }
  }, [userid, username, token]);

  // Función para obtener las organizaciones con su jerarquía
  const fetchOrganizations = useCallback(async () => {
    try {
      const orgsData = {
        transactiontype: "getClientsWithHierarchy",
        userid,
        username,
        token,
      };
      const responseOrgs = await fetchUtility(orgsData, "clientmanagement");
      setOrganizations(responseOrgs);
    } catch (error) {
      console.error("Error al obtener organizaciones:", error);
    }
  }, [userid, username, token]);

  // Manejador de actualización que mantiene el estado de expansión
  const handleRefresh = async (type) => {
    setIsLoading(true);
    if (type === "users") {
      await fetchUsers();
    } else if (type === "organizations") {
      await fetchOrganizations();
    }
    setIsLoading(false);
  };

  // Efecto para cargar los datos iniciales
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchUsers(), fetchOrganizations()]);
      setIsLoading(false);
    };
    fetchData();
  }, [fetchUsers, fetchOrganizations]);

  // Manejador seguro para la expansión/contracción de nodos
  const handleToggleExpand = useCallback((nodeId) => {
    setExpandedNodes((prev) => {
      const currentExpanded = Array.isArray(prev) ? prev : [];
      if (currentExpanded.includes(nodeId)) {
        return currentExpanded.filter((id) => id !== nodeId);
      } else {
        return [...currentExpanded, nodeId];
      }
    });
  }, []);

  // Preparamos los datos para el árbol
  const treeData = [
    {
      label: "Users",
      children: groupUsersByClient(users),
      isRoot: true,
      type: "users",
      treeLevel: 0,
    },
    {
      label: "Organizations",
      children: organizations.map((org) => transformOrganizationData(org, 1)),
      isRoot: true,
      type: "organizations",
      treeLevel: 0,
    },
  ];

  // Renderizado del componente
  return (
    <div className={styles.treeViewWrapper}>
      <div className={styles.treeView}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          treeData.map((item, index) => (
            <TreeItem
              key={index}
              {...item}
              onNodeSelect={onNodeSelect}
              onAddOrganization={onAddOrganization}
              onRefresh={handleRefresh}
              expandedNodes={expandedNodes}
              onToggleExpand={handleToggleExpand}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default TreeUserView;
