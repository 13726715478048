import React, { useState, useEffect, useContext, useCallback } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import styles from "./TreeView.module.css";
import OrganizationsIcon from "./img/Organizations.png";
import SingleOrganizationIcon from "./img/Single-Organization.png";
import RefreshIcon from "./img/refresh.png";
import InfoIcon from "./img/info.png";

const LoadingSpinner = () => (
  <div className={styles.loadingContainer}>
    <div className={styles.spinner}></div>
    <p>Cargando organizaciones...</p>
  </div>
);

// Primero definimos la función auxiliar que necesitamos para transformar los datos
const transformOrganizationData = (org) => {
  return {
    label: org.client,
    id: org.clientid,
    children: org.children
      ? org.children.map((child) => transformOrganizationData(child))
      : [],
  };
};

const TreeItem = ({
  label,
  id,
  children,
  onNodeSelect,
  onRefresh,
  level = 0,
  isRoot = false,
  expandedNodes = [], // Agregamos el estado de nodos expandidos
  onToggleExpand // Agregamos la función para manejar la expansión
}) => {
  // Generamos un ID único para este nodo
  const nodeId = isRoot ? 'root' : `org-${id}`;
  // Verificamos si este nodo está expandido
  const isExpanded = expandedNodes.includes(nodeId);

  const [showInfoIcon, setShowInfoIcon] = useState(false);
  const [showRefreshIcon, setShowRefreshIcon] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    if (children && children.length > 0) {
      // Llamamos a la función del padre para actualizar el estado
      onToggleExpand(nodeId);
    }
  };

  const handleRefreshClick = (e) => {
    e.stopPropagation();
    if (onRefresh) {
      onRefresh();
    }
  };

  const handleInfoClick = (e) => {
    e.stopPropagation();
    onNodeSelect(id);
  };

  const handleMouseEnter = () => {
    if (!isRoot) {
      setShowInfoIcon(true);
    }
    if (isRoot) {
      setShowRefreshIcon(true);
    }
  };

  const handleMouseLeave = () => {
    setShowInfoIcon(false);
    setShowRefreshIcon(false);
  };

  const getIcon = () => {
    return isRoot ? OrganizationsIcon : SingleOrganizationIcon;
  };

  return (
    <div className={styles.treeItem} style={{ marginLeft: `${level * 20}px` }}>
      <div
        className={styles.treeItemContent}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className={styles.toggle}>
          {children && children.length > 0 ? (isExpanded ? "▼" : "▶") : "-"}
        </span>
        <img
          src={getIcon()}
          alt={isRoot ? "Organizations" : "Organization"}
          className={styles.icon}
        />
        <span className={styles.label}>{label}</span>
        <div className={styles.iconContainer}>
          {showRefreshIcon && (
            <img
              src={RefreshIcon}
              alt="Refresh"
              className={styles.refreshIcon}
              onClick={handleRefreshClick}
            />
          )}
          {showInfoIcon && (
            <img
              src={InfoIcon}
              alt="Info"
              className={styles.infoIcon}
              onClick={handleInfoClick}
            />
          )}
        </div>
      </div>
      {isExpanded && children && children.length > 0 && (
        <div className={styles.childrenContainer}>
          {children.map((child, index) => (
            <TreeItem
              key={index}
              {...child}
              onNodeSelect={onNodeSelect}
              onRefresh={onRefresh}
              level={level + 1}
              expandedNodes={expandedNodes}
              onToggleExpand={onToggleExpand}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const TreeOrganizationView = ({ onNodeSelect }) => {
  const context = useContext(UserContext);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // Agregamos el estado para mantener registro de los nodos expandidos
  const [expandedNodes, setExpandedNodes] = useState([]);
  
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;

  // Función para manejar la expansión/contracción de nodos
  const handleToggleExpand = useCallback((nodeId) => {
    setExpandedNodes(prev => {
      const currentExpanded = Array.isArray(prev) ? prev : [];
      if (currentExpanded.includes(nodeId)) {
        return currentExpanded.filter(id => id !== nodeId);
      } else {
        return [...currentExpanded, nodeId];
      }
    });
  }, []);

  const fetchOrganizations = useCallback(async () => {
    setIsLoading(true);
    const bodyData = {
      transactiontype: "getClientsWithHierarchy",
      userid,
      username,
      token,
    };

    try {
      const responseData = await fetchUtility(bodyData, "clientmanagement");
      setOrganizations(responseData);
    } catch (error) {
      console.error("Error al obtener organizaciones:", error);
    } finally {
      setIsLoading(false);
    }
  }, [userid, username, token]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  const handleRefresh = async () => {
    await fetchOrganizations();
  };

  const treeData = {
    label: "Organizations",
    children: organizations.map((org) => transformOrganizationData(org)),
    isRoot: true,
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.treeView}>
      <TreeItem
        {...treeData}
        onNodeSelect={onNodeSelect}
        onRefresh={handleRefresh}
        expandedNodes={expandedNodes}
        onToggleExpand={handleToggleExpand}
      />
    </div>
  );
};

export default TreeOrganizationView;
