import config from "../config/config";

const fetchUtility = async (bodyData, component) => {
  const apiurl = config.API_URLS[component];

    /*console.log("******fetchUtility******");
    console.log("component:  " + component);
    console.log("apiurl:  " + apiurl);
    console.log("bodyData:  " + JSON.stringify(bodyData));*/

  if (!apiurl) {
    console.error(
      `URL de la API no encontrada para el componente: ${component}`
    );
    throw new Error(`Componente desconocido: ${component}`);
  }

  try {
    const response = await fetch(apiurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    });

    if (!response.ok) {
      throw new Error(
        `Error al realizar la solicitud: ${response.status} - ${response.statusText}`
      );
    }

    const data = await response.json();

    //console.log("data:  " + JSON.stringify(data));

    return data;
  } catch (error) {
    //console.error("Error al realizar la solicitud fetch:", error);
    //throw error;
    return [];
  }
};

export default fetchUtility;
