import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  // Estilos de página
  page: {
    backgroundColor: "#FFFFFF",
    padding: 40,
    position: "relative",
  },
  frontPage: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
  },
  disclaimerPage: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
  },

  // Estilos de contenedor principal
  content: {
    flex: 1,
    marginTop: 40,
  },
  pageContent: {
    flex: 1,
    marginTop: 10,
  },
  mainContainer: {
    padding: 20,
    width: "100%",
  },

  section: {
    marginBottom: 20,
    paddingBottom: 10,
  },

  sectionContainer: {
    marginBottom: 20,
    paddingBottom: 10,
  },
  diagnosticItem: {
    marginBottom: 20,
    paddingLeft: 10,
  },

  // Estilos de encabezado y pie de página
  header: {
    marginBottom: 20,
    paddingBottom: 10,
    position: "relative",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 40,
    right: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerText: {
    fontSize: 10,
    color: "gray",
  },
  pageNumber: {
    fontSize: 10,
    color: "gray",
  },

  // Estilos de título
  mainTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 20,
    color: "#000000",
  },
  titleContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: 40,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 20,
    color: "#000000",
  },
  frontPageTitle: {
    fontSize: 36,
    fontWeight: 700,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 15,
    color: "#000000",
    paddingBottom: 5,
    borderBottom: "1pt solid #000000",
  },

  // Estilos de texto
  objectiveText: {
    fontSize: 11,
    lineHeight: 1.4,
    marginBottom: 10,
    color: "#000000",
  },
  h1: {
    fontSize: 24,
    marginBottom: 10,
    fontWeight: 700,
    color: "#000000",
  },
  h2: {
    fontSize: 20,
    marginBottom: 8,
    fontWeight: 700,
    color: "#000000",
  },
  h3: {
    fontSize: 18,
    marginBottom: 6,
    fontWeight: 700,
    color: "#000000",
  },
  p: {
    fontSize: 11,
    marginBottom: 5,
    lineHeight: 1.5,
    color: "#000000",
  },
  paragraph: {
    fontSize: 11,
    marginBottom: 5,
    color: "#000000",
    lineHeight: 1.4,
  },
  strong: {
    fontWeight: 700,
  },
  bold: {
    fontWeight: "bold",
  },

  // Estilos de riesgo
  riskLevelContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    padding: 5,
    borderRadius: 4,
    marginTop: 5,
  },
  riskIcon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  riskLevelText: {
    fontSize: 11,
    color: "#000000",
    marginLeft: 5,
  },
  riskItem: {
    marginLeft: 20,
    marginTop: 5,
    marginBottom: 5,
  },

  // Estilos de imagen y logo
  logo: {
    width: 150,
    objectFit: "contain",
  },
  logoSmall: {
    width: 100,
    objectFit: "contain",
  },
  imageWrapper: {
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  contentImage: {
    maxWidth: "70%",
    maxHeight: 300,
    objectFit: "contain",
  },
  imageCaption: {
    fontSize: 10,
    marginTop: 10,
    textAlign: "center",
  },
  imageSpacerBefore: {
    height: 30,
  },
  imageSpacerAfter: {
    height: 30,
  },

  // Estilos de gráfico
  chartContainer: {
    width: "100%",
    height: 400,
    marginVertical: 20,
  },
  chart: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },

  // Estilos de disclaimer
  disclaimerContentWrapper: {
    flexDirection: "row",
    marginTop: 20,
    marginBottom: 20,
  },
  disclaimerContent: {
    flex: 1,
  },
  disclaimerBox: {
    flex: 1,
    border: "1 solid black",
    padding: 10,
  },

  // Estilos de líneas decorativas
  redLine: {
    borderBottomColor: "red",
    borderBottomWidth: 2,
    marginBottom: 40,
  },
  redLineVertical: {
    width: 2,
    backgroundColor: "red",
    marginRight: 10,
  },
  // ... otros estilos ...
  riskContainer: {
    alignItems: "center",
  },
  riskText: {
    fontSize: 11,
    textAlign: "center",
  },

  //renderDiagnosticContent styles
  diagnosticContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  diagnosticSection: {
    marginBottom: 15,
  },
  diagnosticTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#000000",
  },
  diagnosticObjective: {
    fontSize: 11,
    marginBottom: 8,
    lineHeight: 1.4,
  },
  diagnosticStrong: {
    fontWeight: "bold",
  },
  diagnosticRiskContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  diagnosticRiskIcon: {
    width: 20,
    height: 20,
    marginHorizontal: 5,
  },
  diagnosticRiskText: {
    fontSize: 11,
    marginLeft: 3,
  },
});

export default styles;
