import React, { useContext, useEffect } from 'react';
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import UserContext from '../../utilities/UserContext';

const PDFRecommendedProducts = ({ products }) => {
  const { updatePdfComponentStatus } = useContext(UserContext);

  useEffect(() => {
    const processProducts = async () => {
      try {
        // Indicar inicio de generación
        updatePdfComponentStatus('recommendedProducts', { loading: true, completed: false });

        // Validar datos
        if (!products || products.length === 0) {
          throw new Error('No hay productos para mostrar');
        }

        // Validar estructura de los datos
        const requiredFields = ['section', 'element', 'description', 'image'];
        const hasAllFields = products.some(product => 
          requiredFields.every(field => product.hasOwnProperty(field))
        );

        if (!hasAllFields) {
          throw new Error('Los datos de productos no tienen la estructura requerida');
        }

        // Marcar como completado exitosamente
        updatePdfComponentStatus('recommendedProducts', { loading: false, completed: true });
      } catch (error) {
        console.error('Error al procesar productos recomendados:', error);
        updatePdfComponentStatus('recommendedProducts', { loading: false, completed: false });
      }
    };

    processProducts();
  }, [products, updatePdfComponentStatus]);

  // Si no hay productos, no renderizar nada
  if (!products || products.length === 0) {
    return null;
  }

  // Obtener la sección del primer producto
  const sectionTitle = products[0]?.section || '';

  const getImageSrc = (imageData, imageName) => {
    if (!imageData || !imageData.data) return null;
    try {
      const bytes = new Uint8Array(imageData.data);
      const base64String = btoa(
        bytes.reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      return `data:image/png;base64,${base64String}`;
    } catch (error) {
      console.error('Error al procesar la imagen:', error);
      return null;
    }
  };

  const styles = StyleSheet.create({
    container: {
      marginTop: 20,
      marginBottom: 30,
      marginHorizontal: 20,
    },
    title: {
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: 15,
    },
    table: {
      border: '1pt solid black',
    },
    tableHeader: {
      flexDirection: 'row',
      backgroundColor: '#FFB6C1',
      borderBottom: '1pt solid black',
      minHeight: 30,
      alignItems: 'center',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottom: '1pt solid #CCCCCC',
      minHeight: 60,
      backgroundColor: '#FFFFFF',
    },
    headerCell: {
      padding: 5,
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cellContainer: {
      padding: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    cellText: {
      fontSize: 10,
      textAlign: 'center',
    },
    priorityCell: {
      width: '15%',
      borderRight: '1pt solid black',
    },
    productCell: {
      width: '25%',
      borderRight: '1pt solid black',
    },
    descriptionCell: {
      width: '60%',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    descriptionText: {
      fontSize: 10,
      textAlign: 'left',
    },
    imageContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: 2,
    },
    productImage: {
      width: 30,
      height: 30,
      marginBottom: 5,
      objectFit: 'contain',
    },
    productName: {
      fontSize: 9,
      textAlign: 'center',
      marginTop: 2,
    },
    errorContainer: {
      padding: 20,
      alignItems: 'center',
    },
    errorText: {
      fontSize: 12,
      color: 'red',
    }
  });

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{sectionTitle}</Text>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <Text style={[styles.headerCell, styles.priorityCell]}>Prioridad</Text>
          <Text style={[styles.headerCell, styles.productCell]}>Producto/Servicio</Text>
          <Text style={[styles.headerCell, styles.descriptionCell]}>Descripción</Text>
        </View>

        {products.map((product, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={[styles.cellContainer, styles.priorityCell]}>
              <Text style={styles.cellText}>{index + 1}</Text>
            </View>
            <View style={[styles.cellContainer, styles.productCell]}>
              <View style={styles.imageContainer}>
                {product.image && product.image.data && (
                  <Image
                    style={styles.productImage}
                    src={getImageSrc(product.image, product.imagename)}
                  />
                )}
                <Text style={styles.productName}>{product.element || ''}</Text>
              </View>
            </View>
            <View style={[styles.cellContainer, styles.descriptionCell]}>
              <Text style={styles.descriptionText}>{product.description || ''}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default PDFRecommendedProducts;