import React, { useContext, useEffect } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import UserContext from '../../utilities/UserContext';

const PDFBarChart = ({ data }) => {
    const { updatePdfComponentStatus } = useContext(UserContext);
    const scale = 100;
    const barWidth = 15;

    useEffect(() => {
        const generateBarChart = async () => {
            try {
                updatePdfComponentStatus('barChart', { loading: true, completed: false });

                if (!data || data.length === 0) {
                    throw new Error('No data available to generate bar chart');
                }

                const requiredFields = ['Section', 'Achieved Points', 'Section Points'];
                const hasAllFields = requiredFields.every(field => 
                    data[0].hasOwnProperty(field));
                
                if (!hasAllFields) {
                    throw new Error('Data does not have the required structure');
                }

                updatePdfComponentStatus('barChart', { loading: false, completed: true });
            } catch (error) {
                console.error('Error generating bar chart:', error);
                updatePdfComponentStatus('barChart', { loading: false, completed: false });
            }
        };

        generateBarChart();
    }, [data, updatePdfComponentStatus]);

    const styles = StyleSheet.create({
        container: {
            height: 450,
            width: '100%',
            padding: 40,
            backgroundColor: 'white',
        },
        chartContainer: {
            height: 300,
            flexDirection: 'row',
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderColor: 'black',
            marginLeft: 50,
            marginRight: 20,
            marginBottom: 50,
        },
        yAxis: {
            position: 'absolute',
            left: 10,
            top: 40,
            bottom: 90,
            width: 30,
            justifyContent: 'space-between',
        },
        yAxisLabel: {
            fontSize: 8,
            textAlign: 'right',
        },
        barGroup: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'center',
            position: 'relative',
        },
        bar: {
            width: barWidth,
            marginHorizontal: 2,
        },
        xAxisLabel: {
            position: 'absolute',
            bottom: -40,
            left: 0,
            right: 0,
            textAlign: 'center',
            fontSize: 8,
        },
        legend: {
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 20,
        },
        legendItem: {
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 10,
        },
        legendBox: {
            width: 10,
            height: 10,
            marginRight: 5,
        },
        legendText: {
            fontSize: 8,
        },
        errorText: {
            fontSize: 12,
            color: '#666666',
            textAlign: 'center',
            marginTop: 20
        },
        loadingText: {
            fontSize: 12,
            color: '#666666',
            textAlign: 'center',
            marginTop: 20
        }
    });

    const renderYAxis = () => (
        <View style={styles.yAxis}>
            {[100, 80, 60, 40, 20, 0].map((value, index) => (
                <Text key={index} style={styles.yAxisLabel}>
                    {value}
                </Text>
            ))}
        </View>
    );

    const renderBars = () => (
        <View style={styles.chartContainer}>
            {data.map((item, index) => (
                <View key={index} style={styles.barGroup}>
                    <View style={[
                        styles.bar,
                        {
                            height: `${(item['Achieved Points'] / scale) * 100}%`,
                            backgroundColor: 'rgba(192, 8, 0, 0.2)',
                            borderColor: 'rgba(192, 8, 0, 1)',
                            borderWidth: 1,
                        }
                    ]} />
                    <View style={[
                        styles.bar,
                        {
                            height: `${(item['Section Points'] / scale) * 100}%`,
                            backgroundColor: 'rgba(125, 211, 214, 0.2)',
                            borderColor: 'rgba(125, 211, 214, 1)',
                            borderWidth: 1,
                        }
                    ]} />
                    <Text style={styles.xAxisLabel}>
                        {item['Section']}
                    </Text>
                </View>
            ))}
        </View>
    );

    const renderLegend = () => (
        <View style={styles.legend}>
            <View style={styles.legendItem}>
                <View style={[
                    styles.legendBox,
                    {
                        backgroundColor: 'rgba(192, 8, 0, 0.2)',
                        borderColor: 'rgba(192, 8, 0, 1)',
                        borderWidth: 1,
                    }
                ]} />
                <Text style={styles.legendText}>Achieved points</Text>
            </View>
            <View style={styles.legendItem}>
                <View style={[
                    styles.legendBox,
                    {
                        backgroundColor: 'rgba(125, 211, 214, 0.2)',
                        borderColor: 'rgba(125, 211, 214, 1)',
                        borderWidth: 1,
                    }
                ]} />
                <Text style={styles.legendText}>Section points</Text>
            </View>
        </View>
    );

    if (!data || data.length === 0) {
        return (
            <View style={styles.container}>
                <Text style={styles.errorText}>No data available to display the chart</Text>
            </View>
        );
    }

    return (
        <View style={styles.container}>
            {renderLegend()}
            {renderYAxis()}
            {renderBars()}
        </View>
    );
};

export default PDFBarChart;