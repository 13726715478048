import React from "react";

const UserContext = React.createContext({
  username: "",
  setUsername: () => {},
  user: "",
  setUser: () => {},
  userid: 0,
  setUserId: () => {},
  clientid: 0,
  setClientId: () => {},
  assessmentid: 0,
  setAssessmentId: () => {},
  signed: false,
  setSigned: () => {},
  enableaccesscode: "",
  setEnableaccesscode: () => {},
  token: "",
  setToken: () => {},
  isPDFLoading: false,
  setIsPDFLoading: () => {},
  // Estado de carga del PDF
  pdfComponentsStatus: {
    dynamicTable: { loading: false, completed: false },
    barChart: { loading: false, completed: false },
    radarChart: { loading: false, completed: false },
    diagnosisReport: { loading: false, completed: false },
    recommendedProducts: { loading: false, completed: false },
    riskRecommendations: { loading: false, completed: false }
  },
  setPdfComponentsStatus: () => {},
  // Método auxiliar para actualizar el estado de un componente específico
  updatePdfComponentStatus: (componentName, status) => {}
});

export default UserContext;