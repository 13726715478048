import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ManageUsers from "../setup/ManageUsers";
import ManageOrganizations from "../setup/ManageOrganizations";
import ManageAssessments from "../setup/ManageAssessments";

export const Setup = () => {
  const [key, setKey] = useState("usermanagement");

  const renderTabContent = (currentKey) => {
    switch (currentKey) {
      case "usermanagement":
        return (
          <div className="row">
            <div className="col-12">
              <ManageUsers />
            </div>
          </div>
        );
      case "organizations":
        return (
          <div className="col-12">
            <ManageOrganizations />
          </div>
        );
      case "assessmentmanagemnt":
        return (
          <div className="col-12">
            <ManageAssessments />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            style={{ backgroundColor: "lightgray" }}
          >
            <Tab eventKey="usermanagement" title="User Management">
              {key === "usermanagement" && renderTabContent(key)}
            </Tab>

            <Tab eventKey="organizations" title="Organization & Clients">
              {key === "organizations" && renderTabContent(key)}
            </Tab>

            <Tab eventKey="assessmentmanagemnt" title="Assessment Management">
              {key === "assessmentmanagemnt" && renderTabContent(key)}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Setup;