import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import RingLoader from "react-spinners/RingLoader";
import DiagnosisReport from "./DiagnosisReport";
import ReportPDF from "./ReportPDF";
import DynamicTable from "./DynamicTable";
import BarChart from "./BarChart";
import RadarChart from "./RadarChart";
import BubbleChart from "./BubbleChart";
import "./FormReports.css";

import {
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ChartDataLabels);

// Registrar los componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export const FormReports = (props) => {
  const context = useContext(UserContext);
  const [responseEvaluationSection, setEvaluationSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [responsebubbleData, setbubbleData] = useState([]);
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;

  useEffect(() => {
    const fetchEvaluationData = async () => {
      const transactionType = "getEvaluationSection";

      const bodyData = {
        transactiontype: transactionType,
        clientid: props.clientid,
        assessmenttypeid: props.assessmenttypeid,
        assessmentid: props.assessmentid,
        userid: userid,
        username: username,
        token: token,
      };

      try {
        const responseData = await fetchUtility(bodyData, "reports");
        setEvaluationSections(responseData);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    const fetchBubbleData = async () => {
      const bodyData = {
        transactiontype: "getBubbleData",
        assessmentid: props.assessmentid,
        userid: userid,
        username: username,
        token: token,
      };

      try {
        const responseData = await fetchUtility(bodyData, "reports");
        setbubbleData(responseData);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchEvaluationData();
    fetchBubbleData();
  }, [
    props.clientid,
    props.assessmenttypeid,
    props.assessmentid,
    userid,
    username,
    token
  ]);

  if (loading) {
    return (
      <>
        <div className="FormReportsLoading">
          LOADING...
          <br />
          <br />
          <RingLoader
            className="FormReportsLoading"
            color={"#000000"}
            loading={loading}
            size={150}
          />
          <br />
          <br />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="Table charts-container chart dataCard">
        <DynamicTable data={responseEvaluationSection} />
      </div>
      <br />
      <div className="chart dataCard">
        <div className="charts-container">
          <BarChart data={responseEvaluationSection} />
          <RadarChart data={responseEvaluationSection} />
        </div>
      </div>
      <br />
      <div className="chart dataCard">
        <BubbleChart data={responsebubbleData} />
      </div>
      <br />
      <DiagnosisReport
        client={props.clientid}
        assessmentid={props.assessmentid}
      />
      <PDFDownloadLink
        document={
          <ReportPDF
            clientid={props.clientid}
            assessmenttypeid={props.assessmenttypeid}
            assessmentid={props.assessmentid}
            userid={userid}
            username={username}
            token={token}
          />
        }
        fileName="reporte_assessment.pdf"
      >
        {({ loading }) =>
          loading ? "Generando PDF..." : "Descargar Reporte PDF"
        }
      </PDFDownloadLink>
    </>
  );
};

export default FormReports;