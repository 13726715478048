import React, { useState, useEffect, useContext } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import PDFDynamicTable from "./PDFDynamicTable";
import PDFBarChart from "./PDFBarChart";
import PDFRadarChart from "./PDFRadarChart";
import PDFDiagnosisReport from "./PDFDiagnosisReport";
import PDFRecommendedProducts from "./PDFRecommendedProducts";
import styles from "./pdfStyles";
import diagnosticStyles from "./pdfDiagnosticStyles";
import fetchUtility from "../../utilities/fetchUtilities";
import parse from "html-react-parser";
import FortinetLogo from "./img/Fortinet-logo.png";
import getRiskIcon from "../../utilities/manageIcons";
import UserContext from "../../utilities/UserContext";

const ReportPDF = ({
  clientid,
  assessmenttypeid,
  assessmentid,
  userid,
  username,
  token,
}) => {
  const [evaluationSections, setEvaluationSections] = useState([]);
  const [assessmentEvaluationSections, setAssessmentEvaluationSections] =
    useState([]);
  const [generalDiagnosis, setGeneralDiagnosis] = useState([]);
  const [generalDiagnosisBySection, setGeneralDiagnosisBySection] = useState(
    []
  );
  const [diagnosticData, setDiagnosticData] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [diagnosticRisks, setDiagnosticRisks] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { pdfComponentsStatus, setIsPDFLoading } = useContext(UserContext);

  // Monitorear el estado general de carga del PDF
  useEffect(() => {
    const isAnyComponentLoading = Object.values(pdfComponentsStatus).some(
      (component) => component.loading
    );

    const areAllComponentsComplete = Object.values(pdfComponentsStatus).every(
      (component) => component.completed
    );

    // Si algún componente está cargando, el PDF está cargando
    setIsPDFLoading(isAnyComponentLoading);

    // Cuando todos los componentes estén completos, asegurarse de que isPDFLoading sea false
    if (areAllComponentsComplete) {
      setIsPDFLoading(false);
    }
  }, [pdfComponentsStatus, setIsPDFLoading]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setIsPDFLoading(true);

      try {
        // Fetch PDF Report data
        const pdfReportData = await fetchUtility(
          {
            transactiontype: "getPDFReport",
            clientid,
            assessmenttypeid,
            userid,
            username,
            token,
          },
          "reports"
        );

        if (Array.isArray(pdfReportData) && pdfReportData.length > 0) {
          setEvaluationSections(pdfReportData);
        }

        // Fetch Evaluation Section data
        const evaluationData = await fetchUtility(
          {
            transactiontype: "getEvaluationSection",
            clientid,
            assessmenttypeid,
            assessmentid,
            userid,
            username,
            token,
          },
          "reports"
        );

        if (Array.isArray(evaluationData)) {
          setAssessmentEvaluationSections(evaluationData);
        }

        // Fetch general diagnosis data
        const genDiagnosisData = await fetchUtility(
          {
            transactiontype: "getGenelDignosis",
            assessmentid,
            userid,
            username,
            token,
          },
          "reports"
        );
        setGeneralDiagnosis(genDiagnosisData);

        // Fetch diagnosis by section data
        const sectionDiagnosisData = await fetchUtility(
          {
            transactiontype: "getGenelDignosisbySection",
            assessmentid,
            userid,
            username,
            token,
          },
          "reports"
        );
        setGeneralDiagnosisBySection(sectionDiagnosisData);

        // Fetch diagnostic data
        const diagnosticData = await fetchUtility(
          {
            transactiontype: "getDiagnosis",
            assessmentid,
            userid,
            username,
            token,
          },
          "reports"
        );
        setDiagnosticData(diagnosticData);

        //fetch getRecommendedProducts
        const recommendedProducts = await fetchUtility(
          {
            transactiontype: "getRecommendedProducts",
            clientid,
            assessmentid,
            userid,
            username,
            token,
          },
          "reports"
        );
        setRecommendedProducts(recommendedProducts);

        setLoading(false);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        setError("Error al cargar los datos: " + error.message);
        setLoading(false);
        setIsPDFLoading(false);
      }
    };

    fetchData();
  }, [
    clientid,
    assessmenttypeid,
    assessmentid,
    userid,
    username,
    token,
    setIsPDFLoading,
  ]);

  useEffect(() => {
    const fetchRisks = async () => {
      if (diagnosticData.length === 0) return;

      try {
        const risksPromises = diagnosticData.map(async (diagnosis) => {
          const risks = await fetchUtility(
            {
              transactiontype: "getRiskRecommendation",
              questionid: diagnosis.questionid,
              assessmentid,
              userid,
              username,
              token,
            },
            "reports"
          );
          return { questionid: diagnosis.questionid, risks };
        });

        const resolvedRisks = await Promise.all(risksPromises);
        const risksMap = resolvedRisks.reduce((acc, { questionid, risks }) => {
          acc[questionid] = risks;
          return acc;
        }, {});

        setDiagnosticRisks(risksMap);
      } catch (error) {
        console.error("Error al obtener los riesgos:", error);
      }
    };

    fetchRisks();
  }, [diagnosticData, assessmentid, userid, username, token]);

  const renderHTML = (htmlString) => {
    // Verificar que htmlString sea una cadena y no sea null/undefined
    if (!htmlString || typeof htmlString !== "string") {
      console.warn("renderHTML recibió un valor no válido:", htmlString);
      return null;
    }

    try {
      const parsed = parse(htmlString);
      return React.Children.map(parsed, (elem, index) => {
        if (typeof elem === "string") {
          return <Text key={index}>{elem}</Text>;
        }
        if (!elem || !elem.type) return null;

        switch (elem.type) {
          case "h1":
            return (
              <Text key={index} style={styles.h1}>
                {renderInlineElements(elem.props.children)}
              </Text>
            );
          case "h2":
            return (
              <Text key={index} style={styles.h2}>
                {renderInlineElements(elem.props.children)}
              </Text>
            );
          case "h3":
            return (
              <Text key={index} style={styles.h3}>
                {renderInlineElements(elem.props.children)}
              </Text>
            );
          case "p":
            return (
              <Text key={index} style={styles.p}>
                {renderInlineElements(elem.props.children)}
              </Text>
            );
          case "strong":
            return (
              <Text key={index} style={styles.strong}>
                {renderInlineElements(elem.props.children)}
              </Text>
            );
          default:
            console.warn("Elemento no manejado:", elem);
            return elem.props?.children ? (
              <Text key={index}>
                {renderInlineElements(elem.props.children)}
              </Text>
            ) : null;
        }
      });
    } catch (error) {
      console.error("Error al parsear HTML:", error);
      return null;
    }
  };

  const renderInlineElements = (children) => {
    if (!children) return null;
    if (typeof children === "string") {
      return children;
    }
    if (!Array.isArray(children) && typeof children === "object") {
      children = [children];
    }
    return React.Children.map(children, (child) => {
      if (!child) return null;
      if (typeof child === "string") {
        return child;
      }
      if (typeof child === "object" && child.type === "strong") {
        return (
          <Text style={styles.strong}>
            {renderInlineElements(child.props.children)}
          </Text>
        );
      }
      if (typeof child === "object" && child.props && child.props.children) {
        return renderInlineElements(child.props.children);
      }
      return null;
    });
  };

  const renderImage = (section) => {
    if (
      section.image &&
      section.image.type === "Buffer" &&
      Array.isArray(section.image.data)
    ) {
      try {
        const uint8Array = new Uint8Array(section.image.data);
        const base64String = btoa(String.fromCharCode.apply(null, uint8Array));
        const imageSrc = `data:image/png;base64,${base64String}`;

        return (
          <View style={styles.imageWrapper}>
            <Image src={imageSrc} style={styles.contentImage} />
            {section.content && (
              <Text style={styles.imageCaption}>{section.content}</Text>
            )}
          </View>
        );
      } catch (error) {
        console.error("Error al procesar la imagen:", error);
        return <Text>Error al cargar la imagen: {error.message}</Text>;
      }
    }
    return null;
  };

  const renderDiagnosticContent = () => {
    const getRiskLevel = (balance) => {
      if (balance >= 0 && balance < 28) return "Bajo";
      if (balance >= 28 && balance < 56) return "Medio";
      if (balance >= 56 && balance < 84) return "Alto";
      if (balance >= 84 && balance <= 100) return "Crítico";
      return "Desconocido";
    };

    let previousSection = "";

    return (
      <View style={diagnosticStyles.container}>
        {diagnosticData.map((diagnosis, index) => {
          const showSection = diagnosis.section !== previousSection;
          const riskLevel = getRiskLevel(diagnosis.riskcriticality);
          const risks = diagnosticRisks[diagnosis.questionid] || [];

          const content = (
            <View key={index} style={diagnosticStyles.section}>
              {showSection && (
                <Text style={diagnosticStyles.sectionTitle}>
                  {diagnosis.section}
                </Text>
              )}
              <View style={diagnosticStyles.contentContainer}>
                <Text style={diagnosticStyles.row}>
                  <Text style={diagnosticStyles.label}>Objetivo: </Text>
                  <Text style={diagnosticStyles.label}>
                    {diagnosis.objective}
                  </Text>
                </Text>

                <Text
                  style={[
                    diagnosticStyles.row,
                    { flexDirection: "row", alignItems: "center" },
                  ]}
                >
                  <Text style={diagnosticStyles.label}>Nivel de Riesgo: </Text>
                  <Image
                    style={diagnosticStyles.riskIcon}
                    src={getRiskIcon(riskLevel)}
                  />
                  <Text style={diagnosticStyles.label}>{riskLevel}</Text>
                </Text>

                {/* Riesgos y Recomendaciones */}
                {risks.map((risk, riskIndex) => (
                  <View key={riskIndex} style={diagnosticStyles.riskItem}>
                    <Text style={diagnosticStyles.row}>
                      <Text style={diagnosticStyles.label}>
                        Riesgo {riskIndex + 1}:{" "}
                      </Text>
                      <Text style={diagnosticStyles.label}>{risk.risk}</Text>
                    </Text>
                    <Text style={diagnosticStyles.row}>
                      <Text style={diagnosticStyles.label}>
                        Recomendación:{" "}
                      </Text>
                      <Text style={diagnosticStyles.label}>
                        {risk.recommendation}
                      </Text>
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          );

          previousSection = diagnosis.section;
          return content;
        })}
      </View>
    );
  };

  const renderContent = (section) => {
    if (section.contenttype === "Image") {
      return (
        <View break>
          <View style={styles.imageSpacerBefore} />
          {renderImage(section)}
          <View style={styles.imageSpacerAfter} />
        </View>
      );
    } else if (section.contenttype === "React Component") {
      // Determinamos qué componente renderizar basado en section.content
      switch (section.content) {
        case "PDFDynamicTable":
          return (
            <View break>
              {section.section && renderHTML(section.section.toString())}
              <PDFDynamicTable data={assessmentEvaluationSections} />
            </View>
          );
        case "PDFBarChart":
          return (
            <View break>
              {section.section && renderHTML(section.section.toString())}
              <PDFBarChart data={assessmentEvaluationSections} />
            </View>
          );
        case "PDFRadarChart":
          return (
            <View break>
              {section.section && renderHTML(section.section.toString())}
              <View style={styles.chartContainer}>
                <PDFRadarChart
                  data={assessmentEvaluationSections}
                  style={styles.chart}
                />
              </View>
            </View>
          );
        case "PDFDiagnosisReport":
          return (
            // Forzamos un salto de página antes y después del componente
            <>
              <View break>
                {section.section && renderHTML(section.section.toString())}
                <PDFDiagnosisReport
                  assessmentid={assessmentid}
                  userid={userid}
                  username={username}
                  token={token}
                />
              </View>
              <View break />
            </>
          );
        case "PDFRiskRecommendations":
          return (
            <>
              {section.section && renderHTML(section.section.toString())}
              {renderDiagnosticContent()}
            </>
          );

        case "PDFRecommendedProducts":
          // Obtener las secciones únicas
          const sections = [
            ...new Set(recommendedProducts.map((product) => product.section)),
          ];

          return (
            <>
              {renderHTML("<p></p>")}
              {sections.map((sectionName, index) => {
                // Filtrar productos por sección
                const sectionProducts = recommendedProducts.filter(
                  (product) => product.section === sectionName
                );

                return (
                  <View key={`product-section-${index}`} break>
                    <PDFRecommendedProducts products={sectionProducts} />
                  </View>
                );
              })}
            </>
          );

        default:
          return (
            <>
              {section.section && renderHTML(section.section.toString())}
              {section.content && renderHTML(section.content.toString())}
            </>
          );
      }
    } else {
      return (
        <>
          {section.section && renderHTML(section.section.toString())}
          {section.content && renderHTML(section.content.toString())}
        </>
      );
    }
  };

  const renderFooter = (pageNumber) => (
    <View style={styles.footer} fixed>
      <Text style={styles.footerText}>www.fortinet.com</Text>
      {/* <Text style={styles.pageNumber}>{pageNumber}</Text> */}
    </View>
  );

  const renderFrontPage = (section) => (
    <Page key="front-page" size="A4" style={styles.frontPage}>
      <View style={styles.header} fixed>
        <Image src={FortinetLogo} style={styles.logo} />
      </View>
      <View style={styles.redLine} />
      <View style={styles.titleContainer}>
        <Text style={styles.frontPageTitle}>
          {section.section && renderHTML(section.section.toString())}
        </Text>
      </View>
      {renderFooter(1)}
    </Page>
  );

  const renderDisclaimerPage = (section, pageNumber) => (
    <Page size="A4" style={styles.disclaimerPage}>
      <View style={styles.header} fixed>
        <Image src={FortinetLogo} style={styles.logo} />
      </View>
      <View style={styles.disclaimerContentWrapper}>
        <View style={styles.redLineVertical} />
        <View style={styles.disclaimerBox}>
          {section.content && renderHTML(section.content.toString())}
        </View>
      </View>
      {renderFooter(pageNumber)}
    </Page>
  );

  const renderRegularPage = (pageContent, pageNumber) => (
    <Page key={`regular-page-${pageNumber}`} size="A4" style={styles.page}>
      <View style={styles.header} fixed>
        <Image src={FortinetLogo} style={styles.logoSmall} />
      </View>
      <View style={styles.content}>{pageContent}</View>
      {renderFooter(pageNumber)}
    </Page>
  );

  if (loading) {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>Cargando datos del reporte...</Text>
          </View>
        </Page>
      </Document>
    );
  }

  if (error) {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>{error}</Text>
          </View>
        </Page>
      </Document>
    );
  }

  if (evaluationSections.length === 0) {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>No se encontraron datos para generar el reporte.</Text>
          </View>
        </Page>
      </Document>
    );
  }

  try {
    const pages = [];
    let currentPage = [];
    let previousSection = null;
    let pageNumber = 1;

    evaluationSections.forEach((section, index) => {
      const isNewSection = section.section !== previousSection;
      const shouldStartNewPage = isNewSection && section.newpage === 1;
      const isSpecialComponent =
        section.contenttype === "React Component" &&
        (section.content === "PDFDiagnosisReport" ||
          section.content === "PDFRiskRecommendations");

      // Si es un componente especial, renderizamos la página actual antes
      if (isSpecialComponent && currentPage.length > 0) {
        pages.push(renderRegularPage(currentPage, pageNumber++));
        currentPage = [];
      }

      if (section.sectionreporttype === "Front page") {
        if (currentPage.length > 0) {
          pages.push(renderRegularPage(currentPage, pageNumber++));
          currentPage = [];
        }
        pages.push(renderFrontPage(section));
        pageNumber++;
      } else if (section.sectionreporttype === "Disclaimer") {
        if (currentPage.length > 0) {
          pages.push(renderRegularPage(currentPage, pageNumber++));
          currentPage = [];
        }
        pages.push(renderDisclaimerPage(section, pageNumber++));
      } else {
        if (
          (shouldStartNewPage || isSpecialComponent) &&
          currentPage.length > 0
        ) {
          pages.push(renderRegularPage(currentPage, pageNumber++));
          currentPage = [];
        }

        // Si es un componente especial, lo renderizamos en su propia página
        if (isSpecialComponent) {
          pages.push(
            renderRegularPage(
              [
                <View key={index} style={styles.section}>
                  {renderContent(section)}
                </View>,
              ],
              pageNumber++
            )
          );
        } else {
          currentPage.push(
            <View key={index} style={styles.section}>
              {renderContent(section)}
            </View>
          );
        }
      }

      previousSection = section.section;
    });

    if (currentPage.length > 0) {
      pages.push(renderRegularPage(currentPage, pageNumber));
    }

    return (
      <Document>
        {pages.map((page, index) =>
          React.cloneElement(page, { key: `page-${index}` })
        )}
      </Document>
    );
  } catch (error) {
    console.error("Error al generar el PDF:", error);
    return (
      <Document>
        <Page key="error-page" size="A4">
          <View>
            <Text>Error al generar el reporte: {error.message}</Text>
          </View>
        </Page>
      </Document>
    );
  }
};

export default ReportPDF;
