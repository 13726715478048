import { StyleSheet } from "@react-pdf/renderer";

export const diagnosticStyles = StyleSheet.create({
  container: {
    marginVertical: 10,
    padding: 10,
  },
  section: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#000000",
  },
  objectiveRow: {
    fontSize: 11,
    marginBottom: 8,
    lineHeight: 1.4,
  },
  row: {
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    fontWeight: "bold",
    fontSize: 11,
  },
  riskRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  riskLabel: {
    marginRight: 5,
  },
  riskLevelGroup: {
    flexDirection: "row",
    alignItems: "center",
  },
  riskIcon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  riskText: {
    fontSize: 11,
  },
  // Nuevos estilos para riesgos y recomendaciones
  risksContainer: {
    marginLeft: 20,
  },
  riskItem: {
    marginBottom: 10,
  },
  riskItemRow: {
    fontSize: 11,
    marginBottom: 5,
    lineHeight: 1.4,
  },
  recommendationRow: {
    fontSize: 11,
    marginBottom: 5,
    lineHeight: 1.4,
    marginLeft: 10,
  },
  text: {
    fontSize: 11,
    color: "#000000",
    flex: 1,
    lineHeight: 1.4,
  },
  row: {
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start", // Cambiado a flex-start para mejor alineación
    width: "100%",
  },
  label: {
    fontWeight: "bold",
    fontSize: 11,
    marginRight: 5,
    minWidth: 120, // Asegura espacio consistente para las etiquetas
  },
});

export default diagnosticStyles;
