import React, { useState, useEffect, useContext, useCallback } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import TreeAssessmentView from "./TreeAssessmentView";
import ModalMessage from "../navegation/ModalMessage";
import styles from "./ManageOrganizations.module.css";

const ManageAssessments = () => {
  const context = useContext(UserContext);
  const [organizations, setOrganizations] = useState([]);
  const [users, setUsers] = useState([]);
  const [assessmentTypes, setAssessmentTypes] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [sendReport, setSendReport] = useState(false);
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;
  const [selectedAssessmentId, setSelectedAssessmentId] = useState("");
  const [mode, setMode] = useState("create");
  const [formData, setFormData] = useState({
    assessmentType: "",
    name: "",
    description: "",
    status: "",
    organization: "",
    user: "",
  });
  const [errors, setErrors] = useState({});

  const clearForm = () => {
    setFormData({
      assessmentType: "",
      name: "",
      description: "",
      status: "",
      organization: "",
      user: "",
    });
    setErrors({});
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    if (newMode === "create") {
      clearForm();
      setSelectedAssessmentId("");
    }
  };

  const validateForm = () => {
    if (mode === "delete") return true;
    const newErrors = {};
    if (!formData.assessmentType) {
      newErrors.assessmentType = "Assessment type is required";
    }
    if (!formData.name.trim()) {
      newErrors.name = "Assessment name is required";
    }
    if (!formData.status) {
      newErrors.status = "Please select a status";
    }
    if (!formData.organization) {
      newErrors.organization = "Please select an organization";
    }
    if (!formData.user) {
      newErrors.user = "Please select a user";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      // Verificamos si estamos en modo modify y el checkbox de envío está marcado
      if (mode === "modify" && sendReport) {
        // Primero verificamos el estado Reporting como ya lo teníamos
        const isReportingStatus = statusList.find(
          (status) =>
            status.componentstatusid === formData.status &&
            status.status === "Reporting"
        );

        if (!isReportingStatus) {
          throw new Error(
            "Assessment must be in Reporting status to send report"
          );
        }

        // Obtenemos la información del usuario que recibirá el reporte
        const getUserInfoBody = {
          transactiontype: "getUserInfo",
          username: username,
          userid: userid,
          manageuserid: formData.user,
          token: token,
        };

        const userInfoResponse = await fetchUtility(
          getUserInfoBody,
          "manageuser"
        );

        if (
          !userInfoResponse ||
          !Array.isArray(userInfoResponse) ||
          userInfoResponse.length === 0
        ) {
          throw new Error("Unable to retrieve user information");
        }

        // Preparamos los parámetros para el link del reporte
        const reportParams = {
          clientid: formData.organization,
          assessmenttypeid: formData.assessmentType,
          assessmentid: selectedAssessmentId,
          type: "pdf-download",
        };

        // Codificamos los parámetros en base64
        const encodedParams = btoa(JSON.stringify(reportParams));

        // Construimos el link completo
        const reportLink = `${window.location.origin}/login/${encodedParams}`;

        console.log("Link: " + reportLink);
        // Obtenemos la plantilla del mensaje de email
        const getMessageBody = {
          transactiontype: "getEmailMessage",
          userid: userid,
          username: username,
          token: token,
          request: "Send Report",
        };

        const messageResponse = await fetchUtility(getMessageBody, "utilities");

        if (!messageResponse || !messageResponse.message) {
          throw new Error("Unable to retrieve email message template");
        }

        const emailTemplate = messageResponse.message[0].message;

        if (!emailTemplate || typeof emailTemplate !== "string") {
          throw new Error("Email template not found in response");
        }

        // Reemplazamos tanto el nombre como la URL en el template
        const emailMessage = emailTemplate
          .replace("*name*", userInfoResponse[0].name)
          .replace("*URL*", reportLink);

        // Enviamos el email con el mensaje personalizado
        const sendEmailBody = {
          transactiontype: "SendEmail",
          userid: userid,
          username: username,
          token: token,
          email: userInfoResponse[0].usremail,
          subject: "Assessment Report",
          message: emailMessage,
        };

        const emailResponse = await fetchUtility(sendEmailBody, "utilities");

        if (!emailResponse || emailResponse.status >= 400) {
          throw new Error("Failed to send email");
        }

        setModalMessage(
          `Report link has been sent successfully to ${userInfoResponse[0].usremail}`
        );
        setShowModal(true);
        return;
      }

      switch (mode) {
        case "create":
          const bodyData = {
            transactiontype: "createAssessment",
            userid: userid,
            username: username,
            token: token,
            assessment: formData.name,
            description: formData.description,
            assessmenttypeid: formData.assessmentType,
            clientid: formData.organization,
            manageuserid: formData.user,
            componentstatusid: formData.status,
          };

          const response = await fetchUtility(bodyData, "assessment");
          clearForm();
          setModalMessage(JSON.stringify(response, null, 2));
          setShowModal(true);
          break;

        case "modify":
          const updateBody = {
            transactiontype: "updateAssessment",
            userid: userid,
            username: username,
            token: token,
            assessment: formData.name,
            description: formData.description,
            assessmenttypeid: formData.assessmentType,
            clientid: formData.organization,
            manageuserid: formData.user,
            componentstatusid: formData.status,
            assessmentid: selectedAssessmentId,
          };

          const updateResponse = await fetchUtility(updateBody, "assessment");
          setModalMessage(JSON.stringify(updateResponse, null, 2));
          setShowModal(true);
          break;

        case "delete":
          const deleteBody = {
            transactiontype: "deleteAssessmentbyid",
            userid: userid,
            username: username,
            token: token,
            assessmentid: selectedAssessmentId,
          };

          const deleteResponse = await fetchUtility(deleteBody, "assessment");
          clearForm();
          setModalMessage(JSON.stringify(deleteResponse, null, 2));
          setShowModal(true);
          break;

        default:
          throw new Error("Invalid operation mode");
      }
    } catch (error) {
      setModalMessage(`Error: ${error.message}`);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const fetchAssessmentInfo = useCallback(
    async (assessmentId) => {
      if (!assessmentId) return;

      const bodyData = {
        transactiontype: "getAssessmemtInfo",
        assessmentid: assessmentId,
        username,
        userid,
        token,
      };

      try {
        const responseData = await fetchUtility(bodyData, "assessment");
        if (responseData && responseData[0]) {
          const assessment = responseData[0];
          setFormData({
            assessmentType: assessment.assessmentypeid,
            name: assessment.assessment,
            description: assessment.description || "",
            status: assessment.componentstatusid,
            organization: assessment.clientid,
            user: assessment.userid,
          });
        }
      } catch (error) {
        console.error("Error fetching assessment information:", error);
      }
    },
    [userid, username, token]
  );

  const fetchOrganizations = useCallback(async () => {
    const bodyData = {
      transactiontype: "getClients",
      userid,
      username,
      token,
    };

    try {
      const response = await fetchUtility(bodyData, "clientmanagement");
      if (Array.isArray(response)) {
        setOrganizations(response);
      } else {
        setOrganizations([]);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
      setOrganizations([]);
    }
  }, [userid, username, token]);

  const fetchUsers = useCallback(async () => {
    const bodyData = {
      transactiontype: "getUsers",
      userid,
      username,
      token,
    };

    try {
      const response = await fetchUtility(bodyData, "manageuser");
      if (Array.isArray(response)) {
        setUsers(response);
      } else {
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    }
  }, [userid, username, token]);

  const fetchAssessmentTypes = useCallback(async () => {
    const bodyData = {
      transactiontype: "getAssessmentType",
      userid,
      username,
      token,
    };

    try {
      const response = await fetchUtility(bodyData, "assessment");
      if (Array.isArray(response)) {
        setAssessmentTypes(response);
      } else {
        setAssessmentTypes([]);
      }
    } catch (error) {
      console.error("Error fetching assessment types:", error);
      setAssessmentTypes([]);
    }
  }, [userid, username, token]);

  const fetchStatusList = useCallback(async () => {
    const bodyData = {
      transactiontype: "getAssessmentStatusList",
      userid,
      username,
      token,
    };

    try {
      const response = await fetchUtility(bodyData, "assessment");
      if (Array.isArray(response)) {
        setStatusList(response);
      } else {
        setStatusList([]);
      }
    } catch (error) {
      console.error("Error fetching status list:", error);
      setStatusList([]);
    }
  }, [userid, username, token]);

  const handleAssessmentSelect = useCallback(
    (assessmentId) => {
      setSelectedAssessmentId(assessmentId);
      if (mode === "create") {
        setMode("modify");
      }
      fetchAssessmentInfo(assessmentId);
    },
    [mode, fetchAssessmentInfo]
  );

  useEffect(() => {
    fetchOrganizations();
    fetchUsers();
    fetchAssessmentTypes();
    fetchStatusList();
  }, [fetchOrganizations, fetchUsers, fetchAssessmentTypes, fetchStatusList]);

  const getSubmitButtonText = () => {
    // Verificamos si estamos en modo modify y el checkbox de envío está marcado
    if (mode === "modify" && sendReport) {
      return "SEND REPORT";
    }

    // Si no es envío de reporte, nos basamos en el modo
    switch (mode) {
      case "create":
        return "Create Assessment";
      case "modify":
        return "Update Assessment";
      case "delete":
        return "Delete Assessment";
      default:
        return "Submit";
    }
  };

  return (
    <div className={styles.manageOrganizationsContainer}>
      <div className={styles.treeViewContainer}>
        <TreeAssessmentView onNodeSelect={handleAssessmentSelect} />
      </div>

      <div className={styles.formContainer}>
        <div className={styles.radioButtons}>
          <label>
            <input
              type="radio"
              value="create"
              checked={mode === "create"}
              onChange={() => handleModeChange("create")}
            />
            Create Assessment
          </label>
          <label>
            <input
              type="radio"
              value="modify"
              checked={mode === "modify"}
              onChange={() => handleModeChange("modify")}
            />
            Modify Assessment
          </label>
          <label>
            <input
              type="radio"
              value="delete"
              checked={mode === "delete"}
              onChange={() => handleModeChange("delete")}
            />
            Delete Assessment
          </label>
        </div>

        <form className={styles.formContent} onSubmit={handleSubmit}>
          <h2>Assessment Management</h2>

          <select
            name="assessmentType"
            value={formData.assessmentType}
            onChange={handleChange}
            disabled={mode === "delete"}
          >
            <option value="">Select Assessment Type...</option>
            {assessmentTypes.map((type) => (
              <option key={type.assessmenttypeid} value={type.assessmenttypeid}>
                {type.asseementype}
              </option>
            ))}
          </select>
          {errors.assessmentType && (
            <span className={styles.error}>{errors.assessmentType}</span>
          )}

          <input
            type="text"
            name="name"
            placeholder="Assessment Name"
            value={formData.name}
            onChange={handleChange}
            readOnly={mode === "delete"}
          />
          {errors.name && <span className={styles.error}>{errors.name}</span>}

          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleChange}
            readOnly={mode === "delete"}
          />

          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            disabled={mode === "delete"}
          >
            <option value="">Select Status...</option>
            {statusList.map((status) => (
              <option
                key={status.componentstatusid}
                value={status.componentstatusid}
              >
                {status.status}
              </option>
            ))}
          </select>

          {selectedAssessmentId &&
            statusList.find(
              (status) =>
                status.componentstatusid === formData.status &&
                status.status === "Reporting"
            ) && (
              <div className={styles.checkboxGroup}>
                <label>
                  <input
                    type="checkbox"
                    name="sendReport"
                    checked={sendReport}
                    onChange={(e) => setSendReport(e.target.checked)}
                    disabled={mode === "delete"}
                  />
                  Send Report to user
                </label>
              </div>
            )}

          {errors.status && (
            <span className={styles.error}>{errors.status}</span>
          )}

          <select
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            disabled={mode === "delete"}
          >
            <option value="">Select Organization...</option>
            {organizations.map((org) => (
              <option key={org.clientid} value={org.clientid}>
                {org.client}
              </option>
            ))}
          </select>
          {errors.organization && (
            <span className={styles.error}>{errors.organization}</span>
          )}

          <select
            name="user"
            value={formData.user}
            onChange={handleChange}
            disabled={mode === "delete"}
          >
            <option value="">Select User...</option>
            {users.map((user) => (
              <option key={user.userid} value={user.userid}>
                {user.username}
              </option>
            ))}
          </select>
          {errors.user && <span className={styles.error}>{errors.user}</span>}

          <input type="submit" value={getSubmitButtonText()} />
        </form>
      </div>
      <ModalMessage
        show={showModal}
        onHide={handleCloseModal}
        message={modalMessage}
      />
    </div>
  );
};

export default ManageAssessments;
