import React, { useState, useEffect, useContext, useCallback } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import TreeUserView from "./TreeUserView";
import ModalMessage from "../navegation/ModalMessage";
import styles from "./ManageUsers.module.css";
import RingLoader from "react-spinners/RingLoader";

const ManageUsers = () => {
  const context = useContext(UserContext);
  const [responseOrganizations, setResponseOrganizations] = useState([]);
  const [signinTypes, setSigninTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;
  const [selectedUserId, setSelectedUserId] = useState("");
  const [mode, setMode] = useState("create");
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    organization: "",
    organizationid: "",
    username: "",
    userid: "",
    enableaccesscode: "",
    reports: false,
    assessments: false,
    setup: false,
    signintypeid: "",
  });
  const [errors, setErrors] = useState({});

  const clearForm = () => {
    const newFormData = {
      name: "",
      lastName: "",
      email: "",
      organization: "",
      organizationid: "",
      username: "",
      userid: "",
      enableaccesscode: "",
      reports: false,
      assessments: false,
      setup: false,
      signintypeid:
        mode === "create" && signinTypes.length >= 2
          ? signinTypes[1].signintypeid.toString()
          : "",
    };
    setFormData(newFormData);
    setSelectedOrganizations([]);
    setErrors({});
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    if (newMode === "create") {
      clearForm();
      setSelectedUserId("");
      // Si ya tenemos los tipos de signin cargados, establecemos el segundo
      if (signinTypes.length >= 2) {
        setFormData((prev) => ({
          ...prev,
          signintypeid: signinTypes[1].signintypeid.toString(),
        }));
      }
    }
  };

  const validateForm = () => {
    if (mode === "delete") return true;
    const newErrors = {};
    if (!formData.name.match(/^[a-zA-Z\s]+$/)) {
      newErrors.name = "Name should only contain letters and spaces";
    }
    if (!formData.lastName.match(/^[a-zA-Z\s]+$/)) {
      newErrors.lastName = "Last name should only contain letters and spaces";
    }
    if (!formData.username.match(/^[a-zA-Z0-9_]+$/)) {
      newErrors.username =
        "Username should only contain letters, numbers, and underscores";
    }
    if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.organizationid) {
      newErrors.organization = "Please select an organization";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRemoveOrganization = (clientId) => {
    setSelectedOrganizations((prev) =>
      prev.filter((org) => org.clientid !== clientId)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setIsFormLoading(true); // Activamos el loading al inicio de cualquier operación

    try {
      if (mode === "delete") {
        const deleteUserBody = {
          userid: userid,
          username: username,
          token: token,
          transactiontype: "deleteUser",
          manageuserid: selectedUserId,
        };

        const response = await fetchUtility(deleteUserBody, "manageuser");

        if (response.status === 200) {
          setModalMessage(response.message);
          clearForm();
        } else if (response.status >= 400) {
          console.warn("Server error response:", response);
          setModalMessage("Error deleting user: Server error occurred");
        } else {
          console.warn("Delete user unexpected response:", response);
          setModalMessage("Unexpected error occurred while deleting user");
        }

        setShowModal(true);
        return;
      }

      if (mode === "modify" && updatePassword) {
        const bodyData = {
          userid: userid,
          username: username,
          token: token,
          transactiontype: "updatePassworbyUserid",
          manageuserid: selectedUserId,
        };

        const response = await fetchUtility(bodyData, "manageuser");
        setModalMessage(response.message);
        setShowModal(true);
        if (response.status < 400) {
          setUpdatePassword(false);
          // Re-habilitar inputs
          const inputs = document.querySelectorAll(
            'input[type="text"], input[type="email"], select, input[type="checkbox"]'
          );
          inputs.forEach((input) => {
            if (input.name !== "updatePassword") {
              input.disabled = false;
            }
          });
        }
        return;
      }

      const transactionType = mode === "create" ? "CreateUser" : "updateUser";

      const bodyData = {
        userid: userid,
        username: username,
        token: token,
        transactiontype: transactionType,
        manageuserid: selectedUserId,
        name: formData.name,
        lastname: formData.lastName,
        [mode === "create" ? "newusername" : "manageusername"]:
          formData.username,
        usremail: formData.email,
        clientid: parseInt(formData.organizationid),
        client: formData.organization,
        enableaccesscode: `1${formData.reports ? "1" : "0"}${
          formData.assessments ? "1" : "0"
        }${formData.setup ? "1" : "0"}`,
        signintypeid: parseInt(formData.signintypeid),
      };

      const response = await fetchUtility(bodyData, "createupdateusr");

      if (response.status >= 400) {
        setModalMessage(`Error: ${response.message}`);
        setShowModal(true);
        return;
      }

      await fetchOrganizations();

      if (mode === "modify") {
        try {
          // Primero eliminar todas las asignaciones existentes
          const deleteAdminUserBody = {
            userid: userid,
            username: username,
            token: token,
            transactiontype: "deteteAdminuser",
            manageuserid: selectedUserId,
          };

          const deleteResponse = await fetchUtility(
            deleteAdminUserBody,
            "manageuser"
          );
          if (deleteResponse.status >= 400) {
            setModalMessage(`Error: ${deleteResponse.message}`);
            setShowModal(true);
            return;
          }

          // Luego agregar las nuevas asignaciones
          for (const org of selectedOrganizations) {
            const addAdminUserBody = {
              userid: userid,
              username: username,
              token: token,
              transactiontype: "addAdminuser",
              manageuserid: selectedUserId,
              clientid: org.clientid,
            };

            const addResponse = await fetchUtility(
              addAdminUserBody,
              "manageuser"
            );
            if (addResponse.status >= 400) {
              setModalMessage(`Error: ${addResponse.message}`);
              setShowModal(true);
              return;
            }
          }

          setModalMessage(
            `User ${formData.username} was successfully updated with ${selectedOrganizations.length} organizations`
          );
        } catch (error) {
          setModalMessage(
            `Error updating user organizations: ${error.message}`
          );
          setShowModal(true);
          return;
        }
      } else {
        setModalMessage(`User ${formData.username} was successfully created`);
      }

      setShowModal(true);
      if (mode === "create") {
        clearForm();
      }
    } catch (error) {
      setModalMessage(`Error: ${error.message}`);
      setShowModal(true);
    } finally {
      setIsFormLoading(false); // Desactivamos el loading al finalizar, independientemente del resultado
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "SigninType") {
      setFormData((prevState) => ({
        ...prevState,
        signintypeid: value,
      }));
    } else if (name === "organization") {
      const selectedOrg = responseOrganizations.find(
        (org) => org.clientid.toString() === value
      );
      setFormData((prevState) => ({
        ...prevState,
        organizationid: value,
        organization: selectedOrg ? selectedOrg.client : "",
      }));
    } else if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleUserSelect = useCallback(
    async (id, type) => {
      if (type === "users") {
        setSelectedUserId(id);
        if (mode === "create") {
          setMode("modify");
        }

        // Activamos el loading solo para el formulario
        setIsFormLoading(true);
        try {
          // Primer fetch: obtener información del usuario
          const bodyData = {
            transactiontype: "getUserInfo",
            username: username,
            userid: userid,
            manageuserid: id,
            token: token,
          };

          const responseData = await fetchUtility(bodyData, "manageuser");
          if (responseData.status === 400) {
            setModalMessage(`Error: ${responseData.message}`);
            setShowModal(true);
            return;
          }

          // Segundo fetch: obtener organizaciones del usuario
          const orgBodyData = {
            transactiontype: "getClientsbyManageuser",
            username: username,
            userid: userid,
            manageuserid: id,
            token: token,
          };

          const userOrgs = await fetchUtility(orgBodyData, "clientmanagement");
          if (userOrgs.status >= 400) {
            setModalMessage(
              `Error: ${
                userOrgs.message || "Failed to fetch user organizations"
              }`
            );
            setShowModal(true);
            return;
          }

          // Procesar y actualizar los datos del formulario
          if (responseData && responseData[0]) {
            const enableaccesscode = responseData[0].enableaccesscode;
            const reportsaux = enableaccesscode?.substring(1, 2) === "1";
            const assessmentsaux = enableaccesscode?.substring(2, 3) === "1";
            const setupaux = enableaccesscode?.substring(3, 4) === "1";

            // Actualizar el estado del formulario
            setFormData({
              username: responseData[0].username || "",
              name: responseData[0].name || "",
              lastName: responseData[0].lastname || "",
              email: responseData[0].usremail || "",
              organization: responseData[0].client || "",
              organizationid: responseData[0].clientid?.toString() || "",
              reports: reportsaux,
              assessments: assessmentsaux,
              setup: setupaux,
              signintypeid: responseData[0].signintypeid?.toString() || "",
            });

            // Actualizar las organizaciones seleccionadas
            if (Array.isArray(userOrgs)) {
              setSelectedOrganizations(
                userOrgs.map((org) => ({
                  clientid: org.clientid,
                  client: org.client,
                  parentId: org.client2parentclient,
                  level: org.level,
                }))
              );
            }
          } else {
            setModalMessage(
              "Error: No se recibieron los datos esperados de la API"
            );
            setShowModal(true);
          }
        } catch (error) {
          setModalMessage(
            `Error al obtener la información del usuario: ${error.message}`
          );
          setShowModal(true);
        } finally {
          // Aseguramos que el loading se desactive al terminar
          setIsFormLoading(false);
        }
      }
    },
    [mode, userid, username, token]
  );

  const fetchOrganizations = useCallback(async () => {
    const bodyData = {
      transactiontype: "getClients",
      userid: userid,
      username: username,
      token: token,
    };

    try {
      const response = await fetchUtility(bodyData, "clientmanagement");
      if (response.status >= 400) {
        setModalMessage(`Error: ${response.message}`);
        setShowModal(true);
        setResponseOrganizations([]);
        return;
      }

      if (Array.isArray(response)) {
        setResponseOrganizations(response);
      } else {
        setModalMessage("La respuesta de organizaciones no es un array");
        setShowModal(true);
        setResponseOrganizations([]);
      }
    } catch (error) {
      setModalMessage(`Error al obtener las organizaciones: ${error.message}`);
      setShowModal(true);
      setResponseOrganizations([]);
    }
  }, [userid, username, token]);

  const fetchSigninTypes = useCallback(async () => {
    const bodyData = {
      transactiontype: "getSignintype",
      userid: userid,
      username: username,
      token: token,
    };

    try {
      const response = await fetchUtility(bodyData, "manageuser");
      if (response.status > 400) {
        setModalMessage(`Error: ${response.message}`);
        setShowModal(true);
        setSigninTypes([]);
        return;
      }

      if (Array.isArray(response)) {
        setSigninTypes(response);
      } else {
        setModalMessage(
          "La respuesta de tipos de inicio de sesión no es un array"
        );
        setShowModal(true);
        setSigninTypes([]);
      }
    } catch (error) {
      setModalMessage(
        `Error al obtener los tipos de inicio de sesión: ${error.message}`
      );
      setShowModal(true);
      setSigninTypes([]);
    }
  }, [userid, username, token]);

  useEffect(() => {
    const loadInitialData = async () => {
      setIsInitialLoading(true);
      try {
        await Promise.all([fetchOrganizations(), fetchSigninTypes()]);
      } catch (error) {
        console.error("Error loading initial data:", error);
        setModalMessage("Error cargando datos iniciales: " + error.message);
        setShowModal(true);
      } finally {
        setIsInitialLoading(false);
      }
    };

    loadInitialData();
  }, [fetchOrganizations, fetchSigninTypes]);

  useEffect(() => {
    if (mode === "create" && signinTypes.length >= 2) {
      setFormData((prev) => ({
        ...prev,
        signintypeid: signinTypes[1].signintypeid.toString(),
      }));
    }
  }, [signinTypes, mode]);

  const handleAddOrganization = useCallback(
    (org) => {
      // Verificar si ya existe la organización
      const alreadyExists = selectedOrganizations.some(
        (selected) => selected.clientid === org.id
      );

      if (alreadyExists) return;

      // Verificar si estamos intentando agregar un hijo de una organización ya seleccionada
      const isChildOfSelected = selectedOrganizations.some(
        (selected) => org.parentId === selected.clientid
      );

      // Verificar si estamos intentando agregar un padre cuando ya tenemos hijos seleccionados
      const hasChildrenSelected = selectedOrganizations.some(
        (selected) => selected.parentId === org.id
      );

      if (isChildOfSelected || hasChildrenSelected) {
        setModalMessage(
          "It is not possible to add child organizations to a parent organization, as by default, you can manage child organizations if you have management permissions for the parent organization."
        );
        setShowModal(true);
        return;
      }

      setSelectedOrganizations((prev) => [
        ...prev,
        {
          clientid: org.id,
          client: org.label,
          parentId: org.parentId,
          level: org.level,
        },
      ]);
    },
    [selectedOrganizations, setModalMessage, setShowModal]
  );

  if (isInitialLoading) {
    return (
      <div className={styles.FormAssessmentLoading}>
        <RingLoader color={"#E1251A"} loading={true} size={150} />
        <div className={styles.loadingText}>LOADING INITIAL DATA...</div>
      </div>
    );
  }

  return (
    <div className={styles.manageUsersContainer}>
      {/* Aquí comienza el árbol de usuarios - nunca se recarga */}
      <div className={styles.treeViewContainer}>
        <TreeUserView
          onNodeSelect={handleUserSelect}
          onAddOrganization={handleAddOrganization}
        />
      </div>

      {/* Contenedor del formulario con manejo de carga independiente */}
      <div className={styles.formContainer}>
        {isFormLoading ? (
          /* Spinner de carga solo para el formulario */
          <div className={styles.formLoadingContainer}>
            <RingLoader color={"#E1251A"} loading={true} size={150} />
            <div className={styles.loadingText}>Loading user data...</div>
          </div>
        ) : (
          /* Contenido del formulario */
          <>
            {/* Radio buttons para selección de modo */}
            <div className={styles.radioButtons}>
              <label>
                <input
                  type="radio"
                  value="create"
                  checked={mode === "create"}
                  onChange={() => handleModeChange("create")}
                />
                Create User
              </label>
              <label>
                <input
                  type="radio"
                  value="modify"
                  checked={mode === "modify"}
                  onChange={() => handleModeChange("modify")}
                />
                Modify User
              </label>
              <label>
                <input
                  type="radio"
                  value="delete"
                  checked={mode === "delete"}
                  onChange={() => handleModeChange("delete")}
                />
                Delete User
              </label>
            </div>

            {/* Formulario principal de usuario */}
            <form className={styles.formContent} onSubmit={handleSubmit}>
              <h2>Manage Users</h2>

              {/* Campos de entrada de datos personales */}
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                readOnly={mode === "delete"}
              />
              {errors.name && (
                <span className={styles.error}>{errors.name}</span>
              )}

              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                readOnly={mode === "delete"}
              />
              {errors.lastName && (
                <span className={styles.error}>{errors.lastName}</span>
              )}

              <input
                type="text"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
                readOnly={mode === "delete"}
              />
              {errors.username && (
                <span className={styles.error}>{errors.username}</span>
              )}

              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                readOnly={mode === "delete"}
              />
              {errors.email && (
                <span className={styles.error}>{errors.email}</span>
              )}

              {/* Selectores de organización y tipo de login */}
              <select
                name="organization"
                value={formData.organizationid}
                onChange={handleChange}
                disabled={mode === "delete"}
              >
                <option value="">Select Organization ...</option>
                {responseOrganizations.map((org) => (
                  <option key={org.clientid} value={org.clientid.toString()}>
                    {org.client}
                  </option>
                ))}
              </select>
              {errors.organization && (
                <span className={styles.error}>{errors.organization}</span>
              )}

              <select
                name="SigninType"
                value={formData.signintypeid}
                onChange={handleChange}
                disabled={
                  mode === "delete" || mode === "modify" || mode === "create"
                }
              >
                <option value="">Signin type</option>
                {signinTypes.map((type) => (
                  <option
                    key={type.signintypeid}
                    value={type.signintypeid.toString()}
                  >
                    {type.signintype}
                  </option>
                ))}
              </select>
              {errors.SigninType && (
                <span className={styles.error}>{errors.SigninType}</span>
              )}

              {/* Checkboxes de permisos */}
              <div className={styles.checkboxGroup}>
                <label>
                  <input
                    name="reports"
                    type="checkbox"
                    checked={formData.reports}
                    onChange={handleChange}
                    disabled={mode === "delete"}
                  />
                  Reports
                </label>
                <label>
                  <input
                    name="assessments"
                    type="checkbox"
                    checked={formData.assessments}
                    onChange={handleChange}
                    disabled={mode === "delete"}
                  />
                  Assessments
                </label>
                <label>
                  <input
                    name="setup"
                    type="checkbox"
                    checked={formData.setup}
                    onChange={handleChange}
                    disabled={mode === "delete"}
                  />
                  Setup
                </label>
              </div>

              {/* Lista de organizaciones seleccionadas */}
              <div className={styles.selectedOrgList}>
                <h3>Manage Clients/Organizations</h3>
                {selectedOrganizations.length > 0 ? (
                  <ul>
                    {selectedOrganizations.map((org) => (
                      <li key={org.clientid}>
                        <span>{org.client}</span>
                        <button
                          type="button"
                          onClick={() => handleRemoveOrganization(org.clientid)}
                          className={styles.removeOrgButton}
                        >
                          ×
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={styles.noOrgs}>No organizations selected</p>
                )}
              </div>

              {/* Opción de actualizar contraseña en modo modify */}
              {mode === "modify" && (
                <div className={styles.checkboxGroup}>
                  <label>
                    <input
                      name="updatePassword"
                      type="checkbox"
                      checked={updatePassword}
                      onChange={(e) => {
                        setUpdatePassword(e.target.checked);
                        const inputs = document.querySelectorAll(
                          'input[type="text"], input[type="email"], select, input[type="checkbox"]'
                        );
                        inputs.forEach((input) => {
                          if (input.name !== "updatePassword") {
                            input.disabled = e.target.checked;
                          }
                        });
                      }}
                    />
                    Actualizar Contraseña
                  </label>
                </div>
              )}

              {/* Botón de submit */}
              <input
                type="submit"
                value={
                  mode === "create"
                    ? "Crear Usuario"
                    : mode === "modify"
                    ? updatePassword
                      ? "ACTUALIZAR CONTRASEÑA"
                      : "Actualizar Usuario"
                    : "Eliminar Usuario"
                }
              />
            </form>
          </>
        )}
      </div>

      {/* Modal para mensajes del sistema */}
      <ModalMessage
        show={showModal}
        onHide={handleCloseModal}
        message={modalMessage}
      />
    </div>
  );
};

export default ManageUsers;
