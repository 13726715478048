import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import fetchUtility from "../../utilities/fetchUtilities";
import styles from "./Login.module.css";
import UserContext from "../../utilities/UserContext";
import logo from "./img/fortinel-logo.png";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = React.useContext(UserContext);

  const [form, setForm] = useState({
    username: "",
    password: "",
    code: "",
    responseLogin: [],
  });
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [is2FA, setIs2FA] = useState(false);
  const [countdown, setCountdown] = useState(300);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reportParams, setReportParams] = useState(null);

  useEffect(() => {
    let timer;
    if (is2FA && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIs2FA(false);
      setErrorMsg("Código expirado. Por favor intente nuevamente.");
      setError(true);
    }
    return () => clearInterval(timer);
  }, [is2FA, countdown]);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    if (pathParts.length > 2) {
      try {
        const decodedParams = JSON.parse(atob(pathParts[2]));
        if (decodedParams.type === "pdf-download") {
          setReportParams(decodedParams);
        }
      } catch (error) {
        console.error("Error processing report parameters:", error);
        setError(true);
        setErrorMsg("Link de reporte inválido. Por favor solicite uno nuevo.");
      }
    }
  }, [location]);

  const handleOnChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const setUserContext = (responseLogin) => {
    userContext.setUsername({ username: form.username });
    userContext.setUser({ user: responseLogin.name });
    userContext.setUserId({ userid: responseLogin.userid });
    userContext.setClientId({ clientid: responseLogin.companyid });
    userContext.setSigned({ signed: responseLogin.isValidUser });
    userContext.setEnableaccesscode({
      enableaccesscode: responseLogin.enableaccesscode,
    });
    userContext.setToken({ token: responseLogin.token });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(false);

    try {
      const bodyDataLogin = {
        signintype: is2FA ? "Two-Factor Authentication" : "Standard",
        username: form.username,
        password: form.password,
        code: form.code,
      };

      const responseLogin = await fetchUtility(bodyDataLogin, "signin");

      // Primera validación: credenciales inválidas
      if (!responseLogin.isValidUser) {
        setError(true);
        setErrorMsg("Credenciales inválidas");
        setIsSubmitting(false);
        return;
      }

      // Autenticación estándar exitosa
      if (responseLogin.signintype === "Standard") {
        setUserContext(responseLogin);
        if (reportParams) {
          navigate("/download-report", {
            state: {
              ...reportParams,
              userid: responseLogin.userid,
              username: responseLogin.username,
              token: responseLogin.token,
            },
          });
        } else {
          navigate("/Home");
        }
        return;
      }

      // Manejo de Two-Factor Authentication
      if (responseLogin.signintype === "Two-Factor Authentication") {
        if (!is2FA) {
          // Primera fase de 2FA: credenciales correctas, mostrar input de código
          setIs2FA(true);
          setCountdown(300);
          setForm((prev) => ({ ...prev, code: "" }));
        } else {
          // Segunda fase de 2FA: validación del código
          if (responseLogin.ontimeocode) {
            setUserContext(responseLogin);
            if (reportParams) {
              navigate("/download-report", {
                state: {
                  ...reportParams,
                  userid: responseLogin.userid,
                  username: responseLogin.username,
                  token: responseLogin.token,
                },
              });
            } else {
              navigate("/Home");
            }
          } else {
            setError(true);
            setErrorMsg("Código inválido o expirado");
          }
        }
      }
    } catch (error) {
      setError(true);
      setErrorMsg("Error al obtener datos: " + error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.wrapper + " " + styles.fadeInDown}>
        <div className={styles.formContent} id="formContent">
          <div className={styles.fadeIn + " " + styles.first}>
            <br />
            <img src={logo} width="150" alt="Logo" />
            <br />
            <br />
            <br />
          </div>
          <form className={styles.Login} onSubmit={(e) => e.preventDefault()}>
            <input
              type="text"
              className={styles.fadeIn + " " + styles.second}
              name="username"
              placeholder="username"
              onChange={handleOnChange}
              disabled={is2FA || isSubmitting}
            />
            <input
              type="password"
              className={styles.fadeIn + " " + styles.third}
              name="password"
              placeholder="password"
              onChange={handleOnChange}
              disabled={is2FA || isSubmitting}
            />
            {is2FA && (
              <>
                <input
                  type="text"
                  className={styles.fadeIn + " " + styles.third}
                  name="code"
                  placeholder="code"
                  onChange={handleOnChange}
                  disabled={isSubmitting}
                />
                <p className={styles.codeMessage}>
                  Ingrese el código que fue enviado a su correo, el código es
                  válido por {formatTime(countdown)}
                </p>
              </>
            )}
            <input
              type="submit"
              className={`${styles.fadeIn} ${styles.fourth} ${
                isSubmitting ? styles.disabled : ""
              }`}
              value={
                isSubmitting
                  ? "Procesando..."
                  : is2FA
                  ? "Validar Código"
                  : "Iniciar Sesión"
              }
              onClick={handleClick}
              disabled={isSubmitting}
            />
          </form>

          {error === true && (
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
